import { SharedoFetch } from '@sharedo/mobile-core'
import { _ } from 'core-js';

function getPaymentsForSharedo(sharedoId) {
    return SharedoFetch.post("/api/v1/public/workItem/findByQuery", {
        search: {
            page: { rowsPerPage: 99, page: 1 },
            graph: {
                ancestorIds: [sharedoId],
                includeAncestors: true,
                maxAncestorDistance: 1
            },
            types: {
                includeTypesDerivedFrom: [
                    "payment-request",
                ]
            },
            phase: {
                includeClosed: true,
                includeRemoved: true,
            },
        },
        enrich: [
            { path: "reference" },
            { path: "title" },
            { path: "phase.name" },
            { path: "createdDate.utc.value" },
            { path: "currency.symbol" },
            { path: "paymentRequest.totalAmount" },
            { path: "roles.supplier.ods.name" }
        ]
    });
}

function getPayment(id) {
    return SharedoFetch.get(`/api/aspects/sharedos/${id}`);
}

function savePayment(id, paymentRequest) {
    return SharedoFetch.put(`/api/v1/public/finance/paymentRequest/${id}`, paymentRequest);
}

function searchGoodsAndServices(sharedoId, paymentRequestId, query, page, pageSize) {
    const parameters = {
        query,
        sharedoId,
        paymentRequestId,
        typeFilter: null,
        participantFilter: null,
        keyDateFilter: null,
        purchaseItemsOnly: true
    };

    const payload = {
        contextId: sharedoId,
        parameters: JSON.stringify(parameters),
        withCounts: true
    }

    return SharedoFetch.post(`/api/searches/advanced/goods-services-adv/${pageSize}/${page}`, payload)
}

async function getChartOfAccountsCode(parentSharedoId, sharedoTypeSystemName) {
    const response = await SharedoFetch.post(`/api/chartofaccounts/findMatchedCodeCombination?ctx_sharedoParentId=${parentSharedoId}`, { sharedoTypeSystemName });

    for (let i = 6; i >= 1; i--) {
        const codes = response[`segment${i}CodeOptions`];
        if (codes && codes.length > 0) {
            return codes[0];
        }
    }
}

export default {
    getPaymentsForSharedo,
    getPayment,
    savePayment,
    searchGoodsAndServices,
    getChartOfAccountsCode
};
